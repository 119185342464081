<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">contact block with form</div>
        <div class="module-preview--contents">
            <p class="font-500 text-base">Submitted form responses will be sent to: <span class="font-600 text-base">{{ getValue('email')}}</span></p>           
        </div>
    </div>      
</template>

<script>
import Widget from "@/mixins/Widget";

export default {
    mixins: [ Widget ],
}
</script>